var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-lg-none d-block py-1 bg-white"},[_c('b-link',{staticClass:"d-flex justify-content-center"},[_c('b-img',{staticClass:"bg-white logo_img",attrs:{"src":require("./../../assets/images/avatars/image.png"),"rounded":"","fluid":"","alt":"profile photo"}})],1)],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-start mt-0",attrs:{"lg":"6"}},[_c('div',{staticClass:"d-lg-flex justify-content-center sticky-top"},[_c('div',{staticClass:"mb-5"},[_c('b-link',{staticClass:"brand-logo pb-5"},[_c('img',{attrs:{"src":require("./../../assets/images/logo/vep-logo.png"),"alt":""}})])],1),_c('div',{staticClass:"p-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)])]),_c('b-col',{staticClass:"auth-bg px-2 p-lg-5 pt-lg-0 pt-2 pb-5 overflow-auto",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-2 mx-auto",attrs:{"sm":"12","md":"10","lg":"10"}},[_c('b-card-title',{staticClass:"fw-bold mb-1 text-start",attrs:{"title-tag":"h4"}},[_vm._v(" Your Basic Details ")]),_c('hr'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Firstname","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null,"placeholder":"Firstname"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Middlename","label-for":"middlename"}},[_c('validation-provider',{attrs:{"name":"Middle Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middle_name","state":errors.length > 0 ? false : null,"placeholder":"Middlename"},model:{value:(_vm.form.middle_name),callback:function ($$v) {_vm.$set(_vm.form, "middle_name", $$v)},expression:"form.middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Lastname","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false : null,"placeholder":"Lasttname"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Country Code","label-for":"Country Code"}},[_c('b-form-select',{attrs:{"options":_vm.countryCodeOptions},model:{value:(_vm.selectCountryCode),callback:function ($$v) {_vm.selectCountryCode=$$v},expression:"selectCountryCode"}})],1)],1),_c('b-col',{attrs:{"lg":"8"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"Phone Number"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"country"}},[_vm._v("Country:")]),_c('v-select',{attrs:{"id":"country","label":"title","options":_vm.countryOption,"get-option-label":_vm.getSelected,"get-option-value":_vm.getOptionValue},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"state"}},[_vm._v("State:")]),_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"state","type":"text","placeholder":"State"},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"city"}},[_vm._v("City:")]),_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","type":"text","placeholder":"City"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"datepicker-placeholder"}},[_vm._v("Date of Birth")]),_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","name":"dob","date-format-options":{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  },"placeholder":"Choose a date","local":"en","initial-date":"2020-06-16"},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Select Gender"}},[_c('div',{staticClass:"demo-inline-spacing gender"},[_c('b-form-radio',{attrs:{"name":"gender","value":"male"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" Male ")]),_c('b-form-radio',{attrs:{"name":"gender","value":"female"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" Female ")]),_c('b-form-radio',{attrs:{"name":"gender","value":"other"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" Other ")])],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Your Bio"}},[_c('b-form-textarea',{attrs:{"id":"textarea-rows","name":"bio","placeholder":"Tall textarea","rows":"8"},model:{value:(_vm.form.bio),callback:function ($$v) {_vm.$set(_vm.form, "bio", $$v)},expression:"form.bio"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"my-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Next ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }