<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 form">
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto my-5 formInput">
          <!-- <div class="logo__container">
            <img src="./../../assets/images/logo/vep-logo.png" alt="" />
          </div> -->

          <b-card-title class="mb-4 font-weight-bold" title-tag="h2">
            What are you interested in?
          </b-card-title>
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            block
            class="mt-2 mb-2 px-3 py-2"
            variant="outline-info"
            @click="handleFullTime"
          >
            Full Time Job Opportunities
          </b-button>
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            class="mt-2 mb-2 px-3 py-2"
            block
            variant="outline-info"
            @click="handleHourly"
          >
            Hourly Consultation
          </b-button>
          <!-- <b-col cols="12" class="mt-3">
            <div class="d-flex justify-content-center">
              <b-button variant="primary" type="submit" class="px-4" @click.prevent="validationForm"> Next </b-button>
            </div>
          </b-col> -->
          <!-- <div class="d-flex flex-column justify-content-center align-items-center mt-4" > -->
          <!-- <b-button type="submit" v-on:click="submit()" variant="primary" block :disabled="invalid"> Next </b-button> -->
          <!-- <b-button type="submit" v-on:click="submit()" variant="primary" block :disabled="invalid"> Next </b-button> -->
        </b-col>
      </b-col>
      <!-- /Select Job Type-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index"
import VuexyLogo from "@core/layouts/components/Logo.vue"
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import Ripple from "vue-ripple-directive"

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      form: {
        consultation: "",
      },

      sideImg: require("@/assets/images/pages/login-v2.svg"),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleHourly() {
      this.form.consultation = "hourly"
      store.dispatch("auth/selectJobType", this.form).then((response) => {
        if (response.data.status === "success") {
          this.$router.push({ name: "paid-consulting-form" })
        }
      })
    },

    handleFullTime() {
      this.form.consultation = "full-time"
      store.dispatch("auth/selectJobType", this.form).then((response) => {
        if (response.data.status === "success") {
          this.$router.push({ name: "fulltime-form" })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@media only screen and (max-width: 950px) {
  .form {
    margin-top: -150px;
  }
  .formInput {
    margin-top: -150px;
  }
  .logo_img {
    width: 120px !important;
    margin-left: -80px;
  }
}
</style>
