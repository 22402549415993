<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 d-lg-flex">
      <b-link class="brand-logo d-lg-none">
        <b-img
          src="./../../assets/images/avatars/image.png"
          rounded
          fluid
          alt="profile photo"
        />
      </b-link>
      <b-col lg="4" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex sticky-top">
          <div class="mb-5 mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device -->
          <div class="pt-5 mt-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register -->
      <b-col
        lg="8"
        class="d-lg-flex align-items-center auth-bg overflow-hidden"
      >
        <b-col sm="8" md="6" lg="12" class="">
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            layout="vertical"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="wizard-vertical py-2 from_wizard"
            @on-complete="formSubmitted"
          >
            <!-- Basic details tab -->
            <tab-content title="Basic Details">
              <b-row>
                <BasicDetails />
              </b-row>
            </tab-content>
            <!-- Job details tab -->
            <tab-content title="Job Details">
              <b-row>
                <PresentJob />
              </b-row>
            </tab-content>

            <!-- Qualification  -->
            <tab-content title="Qualification Details">
              <b-row>
                <QualificationDetails />
              </b-row>
            </tab-content>

            <!-- job link -->
            <tab-content title="Job Select">
              <b-row>
                <JobSelect />
              </b-row>
            </tab-content>
            <!-- full time job tab -->
            <tab-content title="Full Time Job">
              <b-row>
                <FullTimeJob />
              </b-row>
            </tab-content>
            <!-- Payment Selection -->
            <tab-content title="Payment Selection">
              <b-row>
                <PaymentSelection />
              </b-row>
            </tab-content>
            <!-- profile photo -->
            <tab-content title="Profile Photo">
              <b-row>
                <ProfilePhoto />
              </b-row>
            </tab-content>
          </form-wizard>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>
<script>
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { email, required } from '@validations';
import { BCol, BImg, BLink, BRow } from 'bootstrap-vue';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import BasicDetails from './BasicDetails.vue';
import FullTimeJob from './FulltimeForm.vue';
import PaymentSelection from './PaidConsultingForm.vue';
import PresentJob from './PresentJob.vue';
import QualificationDetails from './Qualification.vue';
import JobSelect from './SelectJobType.vue';
import ProfilePhoto from './SetProfilePicture.vue';

export default {
  components: {
    BasicDetails,
    PresentJob,
    QualificationDetails,
    JobSelect,
    FullTimeJob,
    PaymentSelection,
    ProfilePhoto,
    BRow,
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    BCol,
    BImg,
    BLink,
  },
  data() {
    return {
      required,
      email,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.from_wizard {
  box-shadow: none;
}
</style>
