<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 pb-5 overflow-auto">
        <b-col sm="12" md="10" lg="10" class="px-2 mx-auto">
          <b-card-title title-tag="h4" class="fw-bold mb-1 text-start">
            Your Basic Details
          </b-card-title>
          <hr />
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <!-- username -->
                <b-col cols="12">
                  <b-form-group label="Firstname" label-for="firstname">
                    <validation-provider
                      #default="{ errors }"
                      name="First name"
                      rules="required"
                    >
                      <b-form-input
                        id="first_name"
                        v-model="form.first_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Firstname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Middlename" label-for="middlename">
                    <validation-provider
                      #default="{ errors }"
                      name="Middle Name"
                    >
                      <b-form-input
                        id="middle_name"
                        v-model="form.middle_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Middlename"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Lastname" label-for="lastname">
                    <validation-provider
                      #default="{ errors }"
                      name="Last name"
                      rules="required"
                    >
                      <b-form-input
                        id="last_name"
                        v-model="form.last_name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Lasttname"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-row class="">
                    <b-col lg="4">
                      <b-form-group
                        label="Country Code"
                        label-for="Country Code"
                      >
                        <b-form-select
                          v-model="selectCountryCode"
                          :options="countryCodeOptions"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="8">
                      <b-form-group
                        label="Phone Number"
                        label-for="Phone Number"
                        class=""
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="phone"
                          rules="required"
                        >
                          <b-form-input
                            id="phone"
                            type="number"
                            v-model="form.phone"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Phone Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <!-- </validation-provider> -->
                  <!-- </b-form-group> -->
                </b-col>
                <b-col cols="12" sm="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <label for="country">Country:</label>
                      <!-- <v-select
                        v-model="form.country"
                        label="name"
                        :options="countryOptions"
                        :get-option-label="getSelected"
                      /> -->
                      <v-select
                        id="country"
                        v-model="form.country"
                        label="title"
                        :options="countryOption"
                        :get-option-label="getSelected"
                        :get-option-value="getOptionValue"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <b-form-group>
                    <label for="state">State:</label>
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <b-form-input
                        id="state"
                        v-model="form.state"
                        type="text"
                        placeholder="State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="6">
                  <b-form-group>
                    <label for="city">City:</label>
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <b-form-input
                        id="city"
                        v-model="form.city"
                        type="text"
                        placeholder="City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <label for="datepicker-placeholder">Date of Birth</label>
                  <b-form-datepicker
                    id="datepicker-placeholder"
                    v-model="form.dob"
                    name="dob"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    }"
                    placeholder="Choose a date"
                    local="en"
                    initial-date="2020-06-16"
                  />
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-form-group label="Select Gender">
                    <div class="demo-inline-spacing gender">
                      <b-form-radio
                        v-model="form.gender"
                        name="gender"
                        value="male"
                      >
                        Male
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.gender"
                        name="gender"
                        value="female"
                      >
                        Female
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.gender"
                        name="gender"
                        value="other"
                      >
                        Other
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Your Bio">
                    <b-form-textarea
                      id="textarea-rows"
                      v-model="form.bio"
                      name="bio"
                      placeholder="Tall textarea"
                      rows="8"
                    />
                  </b-form-group>
                </b-col>

                <!-- login button -->
                <b-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    class="my-2"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Next
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from "@/libs/axios"
import store from "@/store/index"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { required } from "@validations"
import {
  BButton,
  BCardTitle,
  BCol,
  BForm,
  BFormRadio,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BRow,
  BFormSelect,
} from "bootstrap-vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import vSelect from "vue-select"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormRadio,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        first_name: null,
        middle_name: null,
        last_name: null,
        dob: null,
        gender: "male",
        bio: null,
        phone: null,
        country: null,
        city: null,
        state: null,
      },
      selectCountryCode: "+91",
      countryCodeOptions: [
        { value: "+61", text: "Australia(+61)" },
        { value: "+91", text: "India(+91)" },
        { value: "+689", text: "French(+689)" },
      ],
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      countryOption: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
    token() {
      return store.state.auth.access_token
    },
  },
  mounted() {
    this.getCountries()
  },
  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    getOptionValue(option) {
      return (option && option.name) || ""
    },
    async getCountries() {
      axios
        .get("/countries")
        .then((res) => {
          this.countryOption = res.data.countries
        })
        .catch((error) => {
          errorResponse(error, this.$router)
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const formData = {
            first_name: this.form.first_name,
            middle_name: this.form.middle_name,
            last_name: this.form.last_name,
            dob: this.form.dob,
            gender: this.form.gender,
            bio: this.form.bio,
            phone: this.form.phone,
            country: this.form.country.name,
            city: this.form.city,
            state: this.form.state,
          }
          axios.post("basic-details", formData).then((response) => {
            if (response.data.status === "success") {
              this.$router.push({ name: "auth-qualification" })
            }
          })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}
.sticky {
  position: sticky;
  top: 0;
}
.gender {
  margin-top: -15px;
}
.logo_img {
  width: 120px !important;
  margin-left: -80px;
}
</style>
